<template>
    <div>
        <div class="ftb_details_report_content" id="previewContainer"><!--  v-if="preview" -->
            <div>
                <div itemscope="" itemtype="https://schema.org/NewsArticle" id="preview_b" class="preview_block">
                    <h6>
                        <a href="javascript:;">title</a>
                    </h6>
                    <div itemprop="articleBody" class="ftb_details_report_inner">
                        <div class="block_img">
                            <img itemprop="image" alt="title" class="img-responsive lazy-preview-image" src="image_url" style="">
                        </div>
                        <div class="ftb_details_report_desc" innerHtml="body">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="preview_detail_table_wrap matchcenterprediction"><!--  v-if="predictions.length" -->
            <div class="preview_detail_table_section">
            </div>
        </div>

        <div class="ftb_headtohead_preview_table_section"><!--  v-if="headToHead && headToHead.length > 0" -->
            <h2>previewHead </h2>
            <div class="match_table_content">
                <div class="ftb_headtohead_preview_table" ><!-- v-for="let item of headToHead" -->
                    <div class="ftb_headtohead_preview_row">
                        <div class="ftb_headtohead_preview_date_col">
                            <!--<span>datetime | date:'dd/MM/yyyy'</span>-->
                            <span>datetime</span><!-- dd/MM/yyyy -->

                        </div>
                        <div class="ftb_headtohead_preview_namestatus_col">
                            <span>team1</span>
                            <a href="javascript:;">0 - 1</a>
                            <span>team2</span>
                        </div>
                        <!--<div class="winlossinlastfive" style="background:#39b54a">W</div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="ftb_headtohead_preview_table_section"><!--  v-if="home && home.matches.length > 0" -->
            <h2>Uganda - previewLast </h2>
            <div class="match_table_content">
                <div class="ftb_headtohead_preview_table" ><!-- v-for="let item of homematches" -->
                    <div class="ftb_headtohead_preview_row">
                        <div class="ftb_headtohead_preview_date_col">
                            <span>datetime</span><!-- dd/MM/yyyy -->
                        </div>
                        <div class="ftb_headtohead_preview_namestatus_col">
                            <span>homeTeam</span>
                            <a href="javascript:;">ft</a>
                            <span>awayTeam</span>
                        </div>
                        <!--<div class="winlossinlastfive" style="background:#39b54a">W</div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="ftb_headtohead_preview_table_section"><!--  v-if="away && away.matches.length > 0" -->
            <h2>Niger - previewLast </h2>
            <div class="match_table_content">
                <div class="ftb_headtohead_preview_table" ><!-- v-for="let item of awaymatches" -->
                    <div class="ftb_headtohead_preview_row">
                        <div class="ftb_headtohead_preview_date_col">
                            <span>datetime</span><!-- dd/MM/yyyy -->
                        </div>
                        <div class="ftb_headtohead_preview_namestatus_col">
                            <span>homeTeam</span>
                            <a href="javascript:;">ft</a>
                            <span>awayTeam</span>
                        </div>
                        <!--<div class="winlossinlastfive" style="background:#39b54a">W</div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name:'MatchDetailPreview',
    data() {
        return{

        }
    },
}

</script>
